import React from 'react';
import ExpenseForm from './components/ExpenseForm';
import ExpensesList from './components/ExpensesList';

function App() {
    return (
        <div className="App">
            <h1>Expense Tracker</h1>
            <ExpenseForm />
            <ExpensesList />

        </div>
    );
}

export default App;
