import React, { useState, useEffect } from 'react';
import EditExpense from './EditExpense'; // Importă componenta de editare

const ExpensesList = () => {
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false); // State pentru modul de editare
    const [selectedExpense, setSelectedExpense] = useState(null); // State pentru cheltuiala selectată

    // Funcția de fetch pentru cheltuieli
    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const response = await fetch('https://expenses.prodek.xyz:3001/api/cheltuieli');
                const data = await response.json();
                setExpenses(data);
                
            } catch (error) {
                console.error('Error fetching expenses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExpenses();
    }, []);

    // Funcția de editare
    const handleEdit = (expense) => {
        setSelectedExpense(expense); // Setează cheltuiala selectată
        setEditing(true); // Activează modul de editare
    };

    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (

        <div>
            {editing ? (
                // Afișează formularul de editare dacă este activat modul de editare
                <EditExpense

                    expense={selectedExpense}
                    onClose={() => {
                        setEditing(false); // Închide modul de editare
                        setSelectedExpense(null); // Resetează cheltuiala selectată
                    }}
                />
            ) : (
                // Afișează tabelul cu cheltuieli dacă modul de editare este dezactivat
                <>
                    <h2>Expenses</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenses.map((expense) => (

                                <tr key={expense.id}>
                                    <td>{expense.id}</td>
                                    <td>{expense.amount}</td>
                                    <td>{expense.description}</td>
                                    <td>{expense.category}</td>
                                    <td>{expense.date}</td>
                                    <td>
                                        <button onClick={() => handleEdit(expense)}>
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default ExpensesList;
